//变量
@c-333: #333;
@c-666: #666;
@c-999: #999;
@c-fff: #ffffff;
@c-2ff: #3072FF;
@c-202: #D00202;

.touTip {
  text-indent: 2em;
}
.popBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  width: 40vw;
  border: 1px solid #eee;
  text-align: center;
  padding: 3vw 0;
  border-radius: 50px;
  a {
    color: #666;
  }
}
.btnTwo {
  background: #ffc107;
  border-color: #ffc107;
  a {
    color: #412C26;
  }
}
.btns {
  position: fixed;
  width: 94vw;
  bottom: 3vw;
  left: 3vw;
  background: #412C26;
  border-radius: 50px;
  padding: 3vw 4vw 3vw 8vw;
  display: flex;
  justify-content: space-between;
}
.buyBtn {
  padding: 3vw 8vw;
  background: #ffc107;
  color: #412C26;
  border-radius: 50px;
}
.loginBg {
  width: 100%;
  display: block;
}
.imgs {
  display: block;
  width: 100%;
}
.c-eee {
  color: #cbcbcb;
}
.c-red {
  color: #dc0000;
}
.conts {
  border-top: 5vw solid #eee;
}
.tips {
  background: #e9e9e9;
  border-radius: 3vw;
  padding: 4vw 3vw;
  padding-bottom: 0;
  .tabCont {
    justify-content: start;
  }
}
.tabInner {
  padding: 3vw;
  padding-bottom: 0;
  border: 1px solid #eee;
  border-radius: 0 0 3vw 3vw;
}
.borderTop{
  border-top: 1px solid #eee;
}
.tabCont {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4vw;
  align-items: center;
}
/deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  background: #ffc107;
  color: #333;
}
/deep/ .van-tabs--card > .van-tabs__wrap {
  height: 45px;
}
/deep/ .van-tabs__nav--card {
  height: 45px;
  border-color: #ffc107;
  border-radius: 2vw 2vw 0 0;
  overflow: hidden;
  margin: 0;
}
/deep/ .van-tabs__nav--card .van-tab {
  border-right-color: #ffc107;
}
/deep/ .van-tabs__nav--card .van-tab {
  color:  #ffc107;
}
/deep/ .van-tabs__line {
  background-color: #ffc107;
}

@blue: @c-2ff;